<template>
  <AdminPageFrame>
    <PageTitle>
      {{ trans('PAGE_TITLE:Subscriptions') }}
    </PageTitle>
    <t-card class="w-full">
      <div class="w-full lg:min-w-modal-gallery" v-if="packagesData">
        <DataTable :data="packagesData" :headers="tableHeaders" @edit="editPackage" @delete="deletePackage">
          <template #ageRange="{ item }">
            {{ item.ageMin + ' - ' + item.ageMax + ' ' + trans('LABEL:years') }}
          </template>
          <template #name="{ item }">
            {{ trans(item.name) }}
          </template>
        </DataTable>
      </div>
    </t-card>
    <!-- Delete package confirm-->
    <Modal
        v-if="isShowDeletePackageModal"
        :show-modal="isShowDeletePackageModal"
        @clicked="isShowDeletePackageModal = false"
        @onConfirm="deletePackageAction"
        :loading="packageDeleting"
        :header="trans('MODAL_TITLE:Attention')"
    >
      <template v-slot:content>
        <div class="flex flex-row items-center mb-4">
          {{ trans('MODAL_DESCRIPTION:DeletePackage') }}
        </div>
      </template>
    </Modal>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import Button from "@/utils/crud/components/Button";
import DataTable from "@/utils/table/DataTable";
import Modal from "@/utils/modal/Modal";
import PageTitle from "@/layouts/components/pages/PageTitle";
import Pagination from "@/views/content/components/Pagination";
import router from "@/router";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SubscriptionsList",
  components: {
    AdminPageFrame,
    Button,
    DataTable,
    Modal,
    PageTitle,
    Pagination,
  },
  data() {
    return {
      tableHeaders: [
        {
          name: 'LABEL:Actions',
          value: 'actions',
        },
        {
          name: 'ID',
          value: 'id'
        },
        {
          name: 'LABEL:Name',
          value: 'name'
        },
        {
          name: 'LABEL:Price',
          value: 'price',
        },
        {
          name: 'LABEL:SalePrice',
          value: 'salePrice',
        },
        {
          name: 'LABEL:Gender',
          value: 'genderDesc',
        },
        {
          name: 'LABEL:AgeRange',
          value: 'ageRange',
        },
        {
          name: 'LABEL:Days',
          value: 'days'
        },
      ],
      currentPage: 1,
      isShowDeletePackageModal: false,
      packageToDelete: null,
    }
  },
  watch: {
    packageDeleted(val) {
      if(val) {
        this.packageToDelete = null;
        this.isShowDeletePackageModal = false;
        this.currentPage = 1;
      }
    }
  },
  computed: {
    ...mapGetters('packages', ['packagesData', 'pagination', 'packageDeleting', 'packageDeleted'])
  },
  methods: {
    ...mapActions('packages', ['fetchShowPackageRequest', 'fetchPackagesRequest', 'deletePackageRequest']),
    next(val) {
      this.currentPage = val;
      this.fetchPackagesRequest({
        page: val,
      });
    },
    deletePackage(id) {
      this.isShowDeletePackageModal = true;
      this.packageToDelete = id;
    },
    editPackage(id) {
      router.push({name: 'admin-package-show', params: {id: id}})
    },
    deletePackageAction() {
      this.deletePackageRequest({id: this.packageToDelete})
    }
  },
  mounted() {
    this.fetchPackagesRequest({page: this.currentPage});
  }
}
</script>
